<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="E-Commerce"
        :filter="filter"
        placeholder="Search Shop Name, Market Place"
        @sidebar="sidebarFilter"
        @search="getListMarketPlace"
        :hasDropdown="false"
        routerPath=""
        :hideCreate="true"
        @btnMethods="addMarketPlace()"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <!-- <pre>{{ shopeeObject }}</pre> -->
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          class="mt-3"
        >
          <template v-slot:cell(banner)="{ item }">
            <!-- <div class="d-flex align-items-center"> -->
            <b-img
              v-if="item.banner"
              class="banner-market-place"
              :src="item.banner"
              alt="market place banner image"
              @error="handleImageSrc"
            />
            <!-- </div> -->
          </template>
          <template v-slot:cell(status)="{ item }">
            <div class="text-left">
              <div
                class="d-flex align-items-center"
                :style="`color:${item.status ? '#27bd89' : 'red'}`"
              >
                <div
                  class="dot-status"
                  :style="`background-color:${item.status ? '#27bd89' : 'red'}`"
                ></div>
                <b>
                  {{ item.status ? "Connected" : "Not Connected" }}
                </b>
              </div>
              <div class="ml-4">
                <small style="color: #acacac">
                  Updated:
                  {{
                    $moment(item.updatedTime).format("DD MMM YYYY HH:mm:ss")
                  }}</small
                >
              </div>
              <div class="ml-4">
                <small style="color: #acacac">
                  Created:
                  {{
                    $moment(item.createdTime).format("DD MMM YYYY HH:mm:ss")
                  }}</small
                >
              </div>
            </div>
          </template>

          <template v-slot:cell(action)="{ item }">
            <div class="row justify-content-center no-gutters">
              <div
                class="col-sm-4 col-12 cursor-pointer"
                @click="handleEdit(item)"
              >
                <font-awesome-icon icon="pen" class="mr-2" />
                <u>Edit</u>
              </div>

              <div
                class="col-sm-4 col-12 cursor-pointer"
                :style="`color:${!item.status ? '#27bd89' : 'red'}`"
                @click="handleMarketPlaceConnect(item)"
              >
                <font-awesome-icon
                  :icon="!item.status ? 'link' : 'unlink'"
                  class="mr-2"
                />
                <u>{{ !item.status ? "Reconnect" : "Disconnect" }}</u>
                <!-- <b-spinner class="align-middle ml-2"></b-spinner> -->
              </div>
            </div>
          </template>

          <template v-slot:cell(marketplace)="{ item }">
            <div class="d-flex align-items-center">
              <img
                class="market-place-image-logo"
                :src="item.marketplaceUrl"
                alt="market place image"
              />
              <div class="ml-3 text-left mr-auto">
                <div class="f-16">
                  <b> {{ item.marketplace }}</b>
                </div>
                <div>{{ item.shopName }}</div>
              </div>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
      <SideBarFilter
        ref="sidebarFilter"
        :filter="filter"
        placeholder="Search Shop Name, Market Place"
        :hideStatusFilter="false"
        @searchAll="getListMarketPlace"
        @clearFilter="clearFilter"
      >
        <template v-slot:filter-option>
          <b-form-group>
            <template #label>
              <b>Shopping Channel:</b><br />
              <b-form-checkbox
                v-model="shopping_SelectedAll"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="ShoppingToggleAll"
              >
                All
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="filter.chanel"
                :options="marketplace_channel"
                textField="name"
                valueField="id"
                :aria-describedby="ariaDescribedby"
                class="ml-4"
                stacked
              >
              </b-form-checkbox-group>
            </template>
          </b-form-group>
          <b-form-group>
            <template #label>
              <b>Status:</b>
              <br />
              <b-form-checkbox
                v-model="Status_SelectedAll"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="StatusToggleAll"
              >
                All
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="filter.status"
                :options="status"
                textField="name"
                valueField="id"
                :aria-describedby="ariaDescribedby"
                class="ml-4"
                stacked
              >
              </b-form-checkbox-group>
            </template>
          </b-form-group>
        </template>
      </SideBarFilter>
      <ModalMarketPlace
        :marketplace_channel="marketplace_channel"
        @handleImageClick="handleImageClick"
      />
      <ModalLoading ref="modalLoading" />

      <b-modal id="modal-edit-name" centered>
        <template #modal-header>
          <b-row class="header-filter no-gutters">
            <b-col lg="10" class="ft-title">Shop Name</b-col>
            <b-col class="text-right">
              <button
                type="button"
                aria-label="Close"
                class="close text-right"
                @click="$bvModal.hide('modal-edit-name')"
              >
                ×
              </button>
            </b-col>
          </b-row>
        </template>
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="formEditMarketPlace.ShopName"
          :v="$v.formEditMarketPlace.ShopName"
          :isValidate="$v.formEditMarketPlace.ShopName.$error"
        />
        <UploadFile
          textFloat="Banner"
          placeholder="Please Choose File"
          format="video"
          :fileName="formEditMarketPlace.banner"
          v-model="formEditMarketPlace.banner"
          name="thumbnail"
          text="*Please upload only .png, .jpg image with ratio 3:2 less than 10 MB"
          accept="image/jpeg, image/png"
          v-on:onFileChange="(val) => onImageChange(val, 'banner', 1)"
          :disabled="isDisable"
          @delete="deleteImage"
          isDelete
        />
        <b-row v-if="type == 'Line'">
          <b-col cols="12">
            <InputText
              textFloat="API-KEY"
              placeholder="API-KEY"
              type="text"
              name="API-KEY"
              v-model="line.api_key"
              :readonly="true"
            />
          </b-col>
          <b-col cols="12">
            <div class="position-icon">
              <button
                type="button"
                class="btn-icon mr-1"
                v-clipboard:copy="line.url_key"
              >
                <font-awesome-icon icon="copy" />
              </button>
            </div>

            <InputText
              textFloat="Url Key"
              placeholder="Url Key"
              type="text"
              name="urlkey"
              v-model="line.url_key"
              :readonly="true"
            />
          </b-col>
        </b-row>

        <b-form-checkbox
          id="auto-price-update"
          v-model="formEditMarketPlace.IsAutoUpdateProduct"
          name="auto-price-update"
          :value="1"
          :unchecked-value="0"
        >
          Auto Update Product Price
        </b-form-checkbox>

        <template #modal-footer>
          <div class="d-flex justify-content-between w-100">
            <b-button
              class="btn-cancel btn-width"
              @click="$bvModal.hide('modal-edit-name')"
              >Cancel
            </b-button>
            <b-button class="main-color btn-save" @click="updateShopName()">
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-store-hub" @hide="clearValue">
        <template #modal-header>
          <b-row class="header-filter no-gutters">
            <b-col lg="10" class="ft-title">Shop Name</b-col>
            <b-col class="text-right">
              <button
                type="button"
                aria-label="Close"
                class="close text-right"
                @click="$bvModal.hide('modal-store-hub')"
              >
                ×
              </button>
            </b-col>
          </b-row>
        </template>
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.name"
          :v="$v.storeHubObject.name"
          :isValidate="$v.storeHubObject.name.$error"
        />

        <InputText
          textFloat="Username"
          placeholder="Username"
          type="text"
          isRequired
          name="store-hub-username"
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.username"
          :v="$v.storeHubObject.username"
          :isValidate="$v.storeHubObject.username.$error"
        />

        <InputText
          textFloat="Password"
          placeholder="Password"
          type="password"
          isRequired
          :isShowPassword="true"
          name="store-hub-password"
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.password"
          :v="$v.storeHubObject.password"
          :isValidate="$v.storeHubObject.password.$error"
        />
        <hr />
        <InputText
          textFloat="Tax (%)"
          placeholder="Tax"
          type="number"
          isRequired
          name="store-hub-Tax"
          :useDot="false"
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.taxRate"
          :v="$v.storeHubObject.taxRate"
          oninput="if(this.value > 2) return this.value = 2;if (this.value.includes('.')) this.value =  this.value.split('.')[0] + '.' + this.value.split('.')[1].slice(0, 2)"
          :isValidate="$v.storeHubObject.taxRate.$error"
        />
        <b-form-checkbox
          id="product-price-update"
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.isUpdateProductPrice"
          name="product-price-update"
        >
          Auto Update Product Price
        </b-form-checkbox>
        <b-form-checkbox
          id="update-product-name"
          :disabled="!storeHubObject.updateAfter"
          v-model="storeHubObject.isUpdateProductName"
          name="update-product-name"
        >
          Auto Update Product Name
        </b-form-checkbox>

        <template #modal-footer>
          <div class="d-flex justify-content-between w-100">
            <b-button
              class="btn-cancel btn-width"
              @click="$bvModal.hide('modal-store-hub')"
              >Cancel
            </b-button>
            <b-button
              class="main-color btn-save"
              @click="connectStoreHub()"
              :disabled="isFetching || !storeHubObject.updateAfter"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <ModalTiktokShopList
      ref="shopList"
      :shop="shopList"
      :token="tiktokToken"
      :selectShop="selectShop"
      @fetch="getListMarketPlace"
    />
    <LineMyShope
      ref="ModalLineShop"
      @fetch="getListMarketPlace"
      :data="lineData"
    />
  </div>
</template>

<script>
const sha256 = require("js-sha256");

import ModalLoading from "@/components/modal/ModalLoading";
import ModalMarketPlace from "@/components/ecommerce/modal";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, minValue } from "vuelidate/lib/validators";
import LineMyShope from "./LineMyShope.vue";
import ModalTiktokShopList from "./ModalTiktokShopList.vue";
import { getBrandCode } from "@/utils/brand_code.js";
export default {
  components: {
    ModalLoading,
    LineMyShope,
    ModalMarketPlace,
    OtherLoading,
    ModalTiktokShopList,
  },
  validations: {
    formEditMarketPlace: { ShopName: { required } },
    storeHubObject: {
      name: { required },
      username: { required },
      password: { required },
      taxRate: { required, minValue: minValue(1) },
    },
  },
  data() {
    return {
      isLoading: false,
      shopping: [],
      marketplace_channel: [],
      shopping_Selected: [],
      shopping_SelectedAll: false,
      shopping_Indeterminate: false,
      status: [
        { name: "Connected", id: 1 },
        { name: "Not Connected", id: 0 },
      ],
      Status_Selected: [],
      Status_SelectedAll: false,
      Status_Indeterminate: false,
      fields: [
        {
          key: "marketplace",
          label: "Market Place",
          tdClass: "text-center",
          thStyle: "width:20%",
        },
        {
          key: "banner",
          label: "Banner",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "d-flex justify-content-center",
          thStyle: "width:20%",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        status: [],
        chanel: [],
        page: 1,
        take: 10,
        brand_code: getBrandCode(),
      },
      shopeeObject: {
        code: "",
        shopId: "",
        branchMarketPlaceId: 0,
        brand_code: "",
      },
      shopName: "",
      shopId: "",
      isBusy: false,
      lazadaObject: {
        code: "",
        brand_code: "",
      },
      lineData: {},
      line: {
        secret_key: "",
        url_key: "",
        api_key: "",
      },
      IsAutoUpdateProduct: 0,
      formEditMarketPlace: {
        Id: 0,
        ShopName: "",
        ShopId: "",
        banner: "",
        IsAutoUpdateProduct: 0,
      },
      type: "",
      storeHubObject: {
        id: 0,
        name: "",
        username: "",
        password: "", //"cb0e675a3b224ded878a154f7851848b",
        isUpdateProductPrice: false,
        isUpdateProductName: false,
        // isUpdateInventory: false,
        updateAfter: true,
        taxRate: 0,
      },
      isHubEdit: false,
      isFetching: false,
      shopList: [],
      selectShop: [],
      tiktokToken: "",
      isImageLoading: "",
      isDisable: false,
    };
    //
  },

  async mounted() {
    this.$bus.$emit("showLoading");
    // console.log(getBrandCode());
    if (this.$route.query.type == "shopee") {
      this.shopeeObject.code = this.$route.query.code;
      this.shopeeObject.shopId = this.$route.query.shop_id;
      this.shopeeObject.branchMarketPlaceId =
        Number(this.$route.query.branchMarketPlaceId) || 0;

      await this.shopeeRegister();
    } else if (this.$route.query.state) {
      await this.tiktokRegister(
        this.$route.query.app_key,
        this.$route.query.code
      );
    } else if (this.$route.query.code) {
      this.lazadaObject.code = this.$route.query.code;

      await this.LazadaRegister();
    } else {
      await this.getMarketPlace();
      await this.getListMarketPlace();
      this.$bus.$emit("hideLoading");
    }
  },
  watch: {
    "filter.chanel"(newValue) {
      if (newValue.length === 0) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = false;
      } else if (newValue.length === this.marketplace_channel.length) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = true;
      } else {
        this.shopping_Indeterminate = true;
        this.shopping_SelectedAll = false;
      }
    },
    "filter.status"(newValue) {
      if (newValue.length === 0) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = false;
      } else if (newValue.length === this.status.length) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = true;
      } else {
        this.Status_Indeterminate = true;
        this.Status_SelectedAll = false;
      }
    },
  },
  destroyed() {},
  methods: {
    clearValue() {
      this.storeHubObject = {
        id: 0,
        name: "",
        username: "",
        password: "", //"cb0e675a3b224ded878a154f7851848b",
        isUpdateProductPrice: false,
        isUpdateProductName: false,
        // isUpdateInventory: false,
        updateAfter: true,
        taxRate: 0,
      };
    },
    handleEdit(val) {
      if (val.salesChannelId == 5) {
        this.storeHubObject = {
          id: val.id,
          name: val.shopName,
          username: val.user_id,
          password: val.password, //"cb0e675a3b224ded878a154f7851848b",
          isUpdateProductPrice: val.isAutoUpdateProduct == 1 ? true : false,
          isUpdateProductName: val.isAutoUpdateProductName == 1 ? true : false,
          // isUpdateInventory: false,
          updateAfter:
            this.$moment(val.updateAfter).year() == "0001" ? false : true,
          taxRate: val.tax_rate,
        };
        this.isHubEdit = true;
        return this.$bvModal.show("modal-store-hub");
      }
      this.$bvModal.show("modal-edit-name");

      this.formEditMarketPlace = {
        Id: val.id,
        ShopName: val.shopName,
        banner: val.banner,
        ShopId: val.shopId,
        IsAutoUpdateProduct: val.isAutoUpdateProduct,
      };

      this.line = {
        url_key: val.url_key,
        api_key: val.api_key,
        secret_key: val.secret_key,
      };

      this.shopId = val.shopId;
      this.type = val.marketplace;
    },
    async updateShopName() {
      this.$v.$touch();
      if (this.$v.formEditMarketPlace.ShopName.$error) return;
      let payload = {
        ShopName: this.shopName,
        ShopId: this.shopId,
        IsAutoUpdateProduct: this.IsAutoUpdateProduct,
      };
      this.$bus.$emit("showLoading");
      const res = await this.axios.put(
        `/MarketPlace`,
        this.formEditMarketPlace
      );
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.$bvModal.hide("modal-edit-name");
        this.successAlert();
        this.getListMarketPlace();
      } else {
        this.errorAlert(res.data.message);
      }
    },
    async LazadaRegister() {
      this.$bus.$emit("showLoading");
      this.isBusy = true;
      this.lazadaObject.brand_code = getBrandCode();
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_ECOMMERCE_API}/Lazada/getToken`,
        this.lazadaObject
      );
      this.$bus.$emit("hideLoading");
      this.$router.replace({
        path: "",
      });
      if (res.data.result == 1) {
        // this.getListMarketPlace();
      } else {
        this.isBusy = false;
        this.isLoading = false;
        this.errorAlert(res.data.message);
        // this.getListMarketPlace();
      }
    },
    async tiktokRegister(app_key, code) {
      this.$bus.$emit("showLoading");
      const app_secret = "194edab488b32e1baa782abef9eb08e42faefaa1";
      let payload = {
        code: code,
        appKey: app_key,
        appSecret: app_secret,
        brand_code: getBrandCode(),
      };
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_ECOMMERCE_API}/Tiktok/getToken`,
        payload
      );
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.$nextTick(() => {
          this.selectShop = res.data.detail.token.data.seller_name;
          this.shopList = res.data.detail.shop.data.shop_list;
          this.tiktokToken = res.data.detail.token;
        });
        this.$refs.shopList.show(res.data.detail);
      } else {
        this.isBusy = false;
        this.isLoading = false;
        this.errorAlert(res.data.message);
        this.getListMarketPlace();
      }
    },
    async shopeeRegister() {
      this.$bus.$emit("showLoading");

      this.shopeeObject.brand_code = getBrandCode();

      const res = await this.$Axios.post(
        `${process.env.VUE_APP_ECOMMERCE_API}/Shopee/getToken`,
        this.shopeeObject
      );
      this.$router.replace({
        path: "",
      });
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        // this.getListMarketPlace();
      } else {
        this.isBusy = false;
        this.isLoading = false;
        this.errorAlert(res.data.message);
        // this.getListMarketPlace();
      }
    },
    async getMarketPlace() {
      const res = await this.axios(`/MarketPlace/getchannel`);
      this.marketplace_channel = res.data.detail;
    },
    async getListMarketPlace() {
      // this.isLoading = true;
      this.isBusy = true;
      const res = await this.axios.post(`/MarketPlace/getList`, this.filter);

      this.items = res.data.detail.detail;
      this.rows = res.data.detail.count;
      this.isBusy = false;
      // this.isLoading = false;
    },
    addMarketPlace() {
      this.$bvModal.show("modal-market-place");
    },
    ShoppingToggleAll(checked) {
      this.filter.chanel = checked
        ? this.marketplace_channel.map((el) => el.id).slice()
        : [];
    },
    StatusToggleAll(checked) {
      this.filter.status = checked
        ? this.status.map((el) => el.id).slice()
        : [];
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    handleChangeTake(value) {
      this.filter.take = value;
      this.getListMarketPlace();
    },
    pagination(page) {
      this.filter.page = page;
      this.getListMarketPlace();
    },

    clearFilter() {
      this.filter = {
        search: null,
        page: 1,
        take: 10,
        chanel: [],
        status: [],
        brand_code: getBrandCode(),
      };
    },
    async handleMarketPlaceConnect(item) {
      if (item.status) {
        this.$bus.$emit("showLoading");
        const res = await this.axios.put(
          `/MarketPlace/disconnect?BranchMarketplaceId=${item.id}`
        );
        this.$bus.$emit("hideLoading");
        if (res.data.result) {
          this.getListMarketPlace();
        } else {
          this.errorAlert(res.data.message);
        }
      } else if (item.salesChannelId == 5) {
        this.storeHubObject = {
          id: item.id,
          name: item.shopName,
          username: item.user_id,
          password: item.password, //"cb0e675a3b224ded878a154f7851848b",
          isUpdateProductPrice: item.isAutoUpdateProduct == 1 ? true : false,
          isUpdateProductName: item.isAutoUpdateProductName == 1 ? true : false,
          // isUpdateInventory: false,
          updateAfter:
            this.$moment(item.updateAfter).year() == "0001" ? false : true,
          taxRate: item.tax_rate,
          status: 1,
        };
        this.isHubEdit = true;
        return this.$bvModal.show("modal-store-hub");
      } else if (item.salesChannelId == 4) {
        this.lineData = { ...item };
        return this.$refs.ModalLineShop.openModal();
      } else if (item.salesChannelId == 6) {
        return this.connectTiktok();
      } else if (item.salesChannelId == 3) {
        return this.connectShopee(item.id);
      } else {
        return this.lazadaLogin(item.shopId);
      }
    },
    async handleImageClick(val) {
      switch (val.toLowerCase()) {
        case "lazada":
          this.lazadaLogin();
          break;
        case "shopee":
          // alert("ยังไม่เปิด");
          this.connectShopee();
          break;
        case "line":
          this.$bvModal.hide("modal-market-place");
          this.$refs.ModalLineShop.openModal();
          break;
        case "storehub":
          this.isHubEdit = false;
          this.$bvModal.show("modal-store-hub");
          this.$bvModal.hide("modal-market-place");
          break;
        case "tiktok":
          this.connectTiktok();
          break;
        default:
          alert("ยังไม่เปิด");
          break;
      }
    },
    async lazadaLogin() {
      const appKey = "125189";
      // const appSecret = "ooemBuDX3mCzMEdRe8nnDO7uwh4xZyuT";
      const domain =
        `https://crm-marketplace-redirect.d-dots.com?redirect=` +
        window.location.origin +
        "/setting/ecommerce";
      const url = `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${domain}&client_id=${appKey}`;
      window.location.href = url;
    },
    async connectShopee(id) {
      const partner_id = process.env.VUE_APP_SHOPEE_PARTNER_ID || 1008114;
      const date = new Date();
      const dateTime = parseInt(date.getTime() / 1000);
      const path = "/api/v2/shop/auth_partner";
      const partnerKey =
        process.env.VUE_APP_SHOPEE_PARTNER_KEY ||
        "be6de4090f2c41913096af7cc52888fc64496967f47553c43f4f6d27a22c516d";

      let protocol = `${window.location.protocol}//`;
      let hostname = window.location.hostname;
      let port = window.location.port ? `:${window.location.port}` : "";
      let redirect_uri = `${process.env.VUE_APP_REDIRECT_MP}/shopee?to=${protocol}${hostname}${port}/setting/ecommerce?type=shopee`;
      if (id) {
        redirect_uri = redirect_uri + "&branchMarketPlaceId=" + id;
      }

      const base_string = `${partner_id}${path}${dateTime}`;

      var hash = sha256.hmac.create(partnerKey);
      hash.update(base_string);
      const sign = hash.hex();
      const url = `${
        process.env.VUE_APP_SHOPEE_API
      }?partner_id=${partner_id}&redirect=${encodeURIComponent(
        redirect_uri
      )}&timestamp=${dateTime}`;
      // const hex = await this.sha256(url);

      window.location.href = url + "&sign=" + sign;
    },
    async connectTiktok() {
      let redirect_uri = `${window.location.origin}/setting/ecommerce`;

      let state = {
        type: "tiktok",
        to: redirect_uri,
        brand_code: getBrandCode(),
      };
      window.location.href = `https://services.tiktokshop.com/open/authorize?service_id=7389337128358741766&state=${encodeURIComponent(
        JSON.stringify(state)
      )}`;
    },
    async connectStoreHub() {
      this.$v.storeHubObject.$touch();

      if (this.$v.storeHubObject.$error) return;
      this.isFetching = true;
      this.$bus.$emit("showLoading");
      var res = null;
      if (!this.isHubEdit) {
        res = await this.axios.post(
          `/MarketPlace/InitStorehub`,
          this.storeHubObject
        );
      } else {
        res = await this.axios.post(
          `/MarketPlace/UpdateStorehub/${this.storeHubObject.id}`,
          this.storeHubObject
        );
      }
      this.isFetching = false;
      this.$bus.$emit("hideLoading");
      if (res.data.result == 0) {
        // await this.getListMarketPlace();
        // this.$bus.$emit("hideLoading");
        // this.$bvModal.hide("modal-store-hub");
        this.errorAlert(JSON.parse(res.data.message).code);
      } else {
        await this.getListMarketPlace();

        this.$bvModal.hide("modal-store-hub");
        this.successAlert();
      }
    },
    arbuf2hex(buffer) {
      var hexCodes = [];
      var view = new DataView(buffer);
      for (var i = 0; i < view.byteLength; i += 4) {
        var value = view.getUint32(i);
        var stringValue = value.toString(16);
        var padding = "00000000";
        var paddedValue = (padding + stringValue).slice(-padding.length);
        hexCodes.push(paddedValue);
      }
      return hexCodes.join("");
    },

    async sha256(hexstr) {
      var buffer = new Uint8Array(
        hexstr.match(/[\da-f]{2}/gi).map(function (h) {
          return parseInt(h, 16);
        })
      );
      return crypto.subtle.digest("SHA-256", buffer).then(async (hash) => {
        return this.arbuf2hex(hash);
      });
    },
    onImageChange(val, key) {
      this.isImageLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = async () => {
          // if (mode == "icon") {
          if (val.size > 103384000)
            return this.$swal("This file is too large", {
              icon: "info",
            });

          var width = parseInt(img.width) / 3;
          var height = parseInt(img.height) / 2;

          // if (parseInt(width) !== parseInt(height)) {
          //   this.$swal("Please upload image with ratio 3:2 !", {
          //     icon: "info",
          //   });

          //   this.isDisable = false;
          //   return this.deleteImage(key);
          // }

          // }

          this.setUpdataImage(reader.result, key);
        };
        img.src = reader.result;
      };
    },
    deleteImage() {
      this.formEditMarketPlace.banner = "";
      this.isImageLoading = false;
    },
    async setUpdataImage(base64, key) {
      var url = await this.saveImagetoDb(base64, false);

      this.isLoadingImage = false;
      this.isDisable = false;

      this.formEditMarketPlace.banner = url;
      // this.form.banner.is_video = 0;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
  },
};
</script>

<style scoped>
.text-log {
  text-decoration: underline;
  font-size: 65%;
  color: rgb(127, 143, 198);
  cursor: pointer;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

::v-deep .modal-header {
  display: block !important;
  background-color: var(--theme-secondary-color) !important;
}

.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}

label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}

.market-place-image-logo {
  width: 50px;
  height: auto;
}

.f-20 {
  font-size: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}

.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-fixed {
  min-width: 150px;
}

.my-actions {
  margin: 0 2em;
}

.right-gap {
  margin-right: auto;
}

.dot-status {
  padding: 10px;
  background: black;
  border-radius: 50%;
  margin-right: 5px;
}
.position-icon {
  position: absolute;
  z-index: 999;
  left: 80px;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.banner-market-place {
  width: 100%;
  max-width: 200px;
  height: auto;
  /* aspect-ratio: 3/2; */
}
</style>
